import React from 'react';
import styled from '@emotion/styled';

// import { responseStructure } from '../../data/apiQueries';

const Container = styled.div`
  overflow-x: auto;
  table {
    width: 750px;
    max-width: 750px;
    border-collapse: collapse;
  }

  th {
    font-weight: bold;
  }

  td,
  th {
    padding: 10px;

    text-align: left;
    font-size: 18px;
  }

  /* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    table {
      width: 100%;
    }

    /* Force table to not be like tables anymore */
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 50%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      /* Label the data */
      content: attr(data-column);

      color: #000;
      font-weight: bold;
    }
  }
`;
let data = {};
function MdxTable({ dataProp }) {
  if (dataProp) {
    data = dataProp;
    console.log('hu hu hu');
    console.log(data);
  }
  return (
    <Container>
      <table id="tb" style={{ minWidth: '400px', wordBreak: 'break-all' }}>
        <thead>
          <tr id="head">
            {data.head.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data.body.map((item, index) => {
            return (
              <tr key={index}>
                {item.map((itemi, indexi) => {
                  return (
                    <td key={String(index) + String(indexi)} data-column={data.head[indexi]}>
                      {itemi}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}

export default MdxTable;
