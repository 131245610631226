import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Introduction",
  "metaTitle": "Introduction is the meta title tag for this page",
  "metaDescription": "This is the meta description for this page"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The following is a code block with JavaScript language Introduction. This page is unique`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`curl --location --request POST 'localhost:8000/api/integration/players' \\
--header 'token: U78FtRBfsMAnecq_q3i8J' \\
--header 'Content-Type: application/json' \\
--data-raw '{
    "data": [
        {
            "Name": "abhay",
            "Email": "slimin3am@gmail.com",
            "Phone": "7666804987",
            "Add": "",
            "DOB": "24-02-1994",
            "Gender": "male",
            "Refercode": "",
            "State": "Maharashtra",
            "District": "Navi Mumbai",
            "RefBy": "",
            "Pincode": "400710",
            "Country": "India",
            "User_id": "98"
        }
    ]
}'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import React from 'react';
`}</code></pre>
    <p>{`Supports multiple languages.`}</p>
    <p>{`The following is a code block with diff. Lines with `}<inlineCode parentName="p">{`+`}</inlineCode>{` highlighted in green shade indicating an addition. Lines with `}<inlineCode parentName="p">{`-`}</inlineCode>{` highlighted in red shade indicating a deletion.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`- const data = ['1','2'];
+ const data = [1,2];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      