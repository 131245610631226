import * as React from 'react';
import OpenedSvg from '../images/opened';
import ClosedSvg from '../images/closed';
import config from '../../../config';
import Link from '../link';
import { useLocation } from '@reach/router'; // NEW

function TreeNode({ className = '', setCollapsed, collapsed, url, title, items, level, ...rest }) {
  const isCollapsed = collapsed[url];
  const loc = useLocation();
  const collapse = () => {
    setCollapsed(url);
  };

  const hasChildren = items.length !== 0;

  let location;

  if (typeof document != 'undefined') {
    location = document.location;
  }
  // React.useEffect(() => {
  //   console.log('successfully url changed');
  //   setLocation(document?.location);
  // }, [loc]);
  React.useEffect(() => {
    console.log('location changed', location);
    console.log('location.pathname');
    console.log(location.pathname);
    console.log('url');
    console.log(url);
    console.log('loc');
    console.log(loc);
    console.log(location.pathname == url);
  }, [loc]);
  // const [location, setLocation] = React.useState(document?.location);
  const active =
    location &&
    (location.pathname === url ||
      location.pathname === config.gatsby.pathPrefix + url ||
      location.pathname === url + '/' ||
      location.pathname === config.gatsby.pathPrefix + url + '/');
  // const [active, setActive] = React.useState(false);
  // const loc = useLocation();

  // const [calculatedClassName, setCalculatedClassName] = React.useState(
  //   `${className} item ${active ? 'active' : ''}`
  // );
  const calculatedClassName = `${className} item ${active ? 'active' : ''}`;
  // React.useEffect(() => {
  //   console.log('calculatedClassName');
  //   console.log(calculatedClassName);
  // }, [calculatedClassName]);
  React.useEffect(() => {
    // if (level == 1) {
    //   console.log('items[0].items[0].url');
    //   console.log(items[0].items[0].url);
    // }
    console.log(`level for title: ${title} is ${level}`);
  }, [location]);

  return (
    <li className={calculatedClassName}>
      {title && (
        <Link
          to={level == 2 ? (items && items.length > 0 ? items[0].url : url) : url}
          // to={
          //   level == 1
          //     ? items && items.length > 0
          //       ? items[0].items[0].url
          //       : 'pleaseNoteThatChildrenForThisTreeNodeDoesNotExist'
          //     : url
          // }
          onClick={collapse}
        >
          {!config.sidebar.frontLine && title && hasChildren ? (
            <button onClick={collapse} aria-label="collapse" className="collapser">
              {!isCollapsed ? <OpenedSvg /> : <ClosedSvg />}
            </button>
          ) : (
            <span className="collapser">&bull;</span>
          )}
          {title}
        </Link>
      )}

      {!isCollapsed && hasChildren ? (
        <ul>
          {items.map((item, index) => (
            <TreeNode
              key={item.url + index.toString()}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              level={level + 1}
              {...item}
            />
          ))}
        </ul>
      ) : null}
    </li>
  );
}

export default TreeNode;
