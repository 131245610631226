import React from 'react';

const generate = (jsonObj) => {
  return <>{JSON.stringify(jsonObj, null, 2)}</>;
};

export const apiQueries = {
  orderPredictionAPI: {
    head: ['Key', 'Required', 'Schema', 'Description'],
    body: [
      ['name', 'true', 'String', 'Name of the person'],
      ['email', 'true', 'String', 'Email Id'],
      ['phone', 'false', 'String', 'Mobile Number'],
      ['address', 'false', 'String', 'Location'],
      ['products', 'true', 'JSON Value', 'Array of product details'],
      ['order_id', 'true', 'String', 'Id of the order ordered'],
      ['total_amount', 'true', 'Integer', 'Amount of the Order'],
      ['discount', 'true', 'Integer', 'Discount offered'],
      ['total_qty', 'false', 'Integer', 'Total quantity offered'],
      ['refferral_code', 'true', 'String', 'Refferral Code for getting benefits'],
      ['user_id', 'true', 'String', 'Unique ID offered to the user'],
      ['subscript_points', 'false', 'Boolean', 'Subscript Points'],
    ],
  },
  orderUpsertAPI: {
    head: ['Key', 'Required', 'Schema', 'Description'],
    body: [
      ['name', 'true', 'String', 'Name of the person'],
      ['email', 'true', 'String', 'Email Id'],
      ['phone', 'false', 'String', 'Mobile Number'],
      ['address', 'false', 'String', 'Location'],
      ['products', 'true', 'JSON Value', 'Array of product details'],
      ['order_id', 'true', 'String', 'Id of the order ordered'],
      ['total_amount', 'true', 'Integer', 'Amount of the Order'],
      ['discount', 'true', 'Integer', 'Discount offered'],
      ['total_qty', 'false', 'Integer', 'Total quantity offered'],
      ['refferral_code', 'true', 'String', 'Refferral Code for getting benefits'],
      ['user_id', 'true', 'String', 'Unique ID offered to the user'],
    ],
  },
  playerUpsertAPI: {
    head: ['Key', 'Required', 'Schema', 'Description'],
    body: [
      ['Name', 'true', 'String', 'Name of the person'],
      ['Email', 'true', 'String', 'Email Id'],
      ['Phone', 'false', 'Number', 'Mobile Number'],
      ['Add', 'false', 'String', 'Location'],
      ['DOB', 'true', 'Date', 'Date of Birth'],
      ['Gender', 'true', 'String', 'Gender(male/female)'],
      ['Refercode', 'true', 'String', 'Referral Code for getting the benefits'],
      ['State', 'true', 'String', 'State of residence'],
      ['District', 'false', 'String', 'District of residence'],
      ['RefBy', 'false', 'String', 'Referred by the person/organization'],
      ['Pincode', 'true', 'Number', 'Pincode of the area of residence'],
      ['Country', 'true', 'String', 'Country'],
      ['User_id', 'true', 'String', 'Unique ID offered to the user'],
    ],
  },
  productUpsertAPI: {
    head: ['Key', 'Required', 'Schema', 'Description'],
    body: [
      ['product_sku_id', 'true', 'String', 'Product Id'],
      ['amount', 'true', 'Number', 'Price of the Product'],
    ],
  },
};

const data = {
  head: ['Status Code', 'Description', 'Response Structure'],
  body: [
    ['200', 'Success', 'Request Based Response'],
    ['400', 'Bad Request', generate({ path: 'String', error: 'String' })],
    ['401', 'Unauthorized', generate({ error: 'String' })],
    ['500', 'Internal server error', generate({ error: 'String' })],
  ],
};

export const responseStructure = {
  general: data,
};
