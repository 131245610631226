import * as React from 'react';
import { StaticQuery, graphql } from 'gatsby';

// import Link from './link';
import config from '../../config';
import { Sidebar, ListItem } from './styles/Sidebar';

function recurseGenJSXForRightSideBar(innerItem, index, level = 1) {
  const itemId = innerItem.title ? innerItem.title.replace(/\s+/g, '').toLowerCase() : '#';
  const arrOfInnerItems = [];
  // if (innerItem.items && innerItem.items.length)
  if (false)
    for (let i = 0; i < innerItem.items.length; i++) {
      const res = recurseGenJSXForRightSideBar(innerItem.items[i], i, level + 1);
      arrOfInnerItems.push(res);
    }
  return (
    <>
      <ListItem key={`${level} ${index}`} to={`#${itemId}`} level={level}>
        {innerItem.title}
      </ListItem>
      {arrOfInnerItems}
    </>
  );
}

const SidebarLayout = ({ location }) => (
  <StaticQuery
    query={graphql`
      query {
        allMdx {
          edges {
            node {
              fields {
                slug
              }
              tableOfContents
            }
          }
        }
      }
    `}
    render={({ allMdx }) => {
      let navItems = [];
      React.useEffect(() => {
        console.log('allMdx this time');
        console.log(allMdx);
      }, [location]);
      let finalNavItems;

      if (allMdx.edges !== undefined && allMdx.edges.length > 0) {
        const navItems = allMdx.edges.map((item, index) => {
          let innerItems;

          if (item !== undefined) {
            const cond1 =
              item.node.fields.slug === location.pathname ||
              config.gatsby.pathPrefix + item.node.fields.slug === location.pathname;
            const cond2 =
              item.node.fields.slug + '/' === location.pathname ||
              config.gatsby.pathPrefix + item.node.fields.slug + '/' === location.pathname;
            if (cond1 || cond2) {
              if (item.node.tableOfContents.items) {
                innerItems = item.node.tableOfContents.items.map((innerItem, index) => {
                  return recurseGenJSXForRightSideBar(innerItem, index);
                });
              }
            }
          }
          if (innerItems) {
            finalNavItems = innerItems;
          }
        });
      }

      if (finalNavItems && finalNavItems.length) {
        return (
          <Sidebar>
            <ul className={'rightSideBarUL'}>
              <li className={'rightSideTitle'}>CONTENTS</li>
              {finalNavItems}
            </ul>
          </Sidebar>
        );
      } else {
        return (
          <Sidebar>
            <ul></ul>
          </Sidebar>
        );
      }
    }}
  />
);

export default SidebarLayout;
