import React from 'react';

import CodeBlock from './codeBlock';

import { languagesCodes } from '../../data/languagesCodes';

import TabsList from './Tabs/TabsList';
import TabsItem from './Tabs/TabsItem';
import TabsContent from './Tabs/TabsContent';
import { TabsStyle } from './Tabs/TabsStyle';

function CustomCodeBlock({ id, children }) {
  const languages = languagesCodes[id]['languages'];
  // const [language, setLanguage] = React.useState('cpp');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const keys = Object.keys(languages);
  const handleClick = (itemIndex) => {
    setSelectedTab(itemIndex);
  };
  keys.forEach((item, index) => {
    console.log('key');
    console.log(item);
    console.log(index);
    console.log(languages[item]);
  });
  React.useEffect(() => {}, []);
  return (
    <>
      <TabsStyle count={keys.length}>
        <TabsList>
          {keys.map((item, index) => (
            <TabsItem
              handleClick={() => handleClick(index)}
              className={selectedTab === index ? 'active' : ''}
              key={index}
            >
              {item}
            </TabsItem>
          ))}
        </TabsList>
        <div className="tabs__content-wrapper">
          {keys.map((item, index) => {
            return (
              <TabsContent key={index} tabId={index} activeTab={selectedTab}>
                <CodeBlock language={languages[item]['language']}>
                  {languages[item]['code']}
                </CodeBlock>
              </TabsContent>
            );
          })}
        </div>
      </TabsStyle>
    </>
  );
}

export default CustomCodeBlock;
