import GithubLink from "../../../../src/GithubLink.js";
import MdxTable from "../../../../src/components/mdxComponents/table.js";
import { responseStructure, apiQueries } from "../../../../src/data/apiQueries";
import CustomCodeBlock from "../../../../src/components/mdxComponents/customCodeBlock.js";
import * as React from 'react';
export default {
  GithubLink,
  MdxTable,
  responseStructure,
  apiQueries,
  CustomCodeBlock,
  React
};