import * as React from 'react';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';

import ThemeProvider from './theme/themeProvider';
import mdxComponents from './mdxComponents';
import Sidebar from './sidebar';
import RightSidebar from './rightSidebar';
import config from '../../config.js';

import { Breadcrumb } from 'gatsby-plugin-breadcrumb';

const Wrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.background};
  .breadcrumb ol {
    display: flex;
    list-style: none;
    color: ${({ theme }) => (theme.colors.background === '#fff' ? '#551a8b' : '#fff')};
  }
  .breadcrumb .breadcrumb__link {
    color: ${({ theme }) => (theme.colors.background === '#fff' ? '#551a8b' : '#fff')};
  }
  .sideBarUL li a {
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.5;
  }
  .sideBarUL li a:hover {
    background-color: ${({ theme }) =>
      theme.colors.background === '#fff' ? '#ebf1f7' : '#3d4f60'};
    opacity: 1;
  }
  .sideBarUL li.active > a {
    color: ${({ theme }) => theme.colors.text};
    opacity: 1;
    font-weight: bold;
    background-color: ${({ theme }) =>
      theme.colors.background === '#fff' ? '#ebf1f7' : '#3d4f60'};
  }
  .firstLevel ul li .collapser svg path {
    fill: ${({ theme }) => (theme.colors.background === '#fff' ? '#3B454E' : '#fff')} !important;
  }
  .hideFrontLine ul li.active > a .collapser svg path {
    // background-color: #1ed3c6;
    // fill: #fff !important;
  }
  @media only screen and (max-width: 767px) {
    display: block;
  }
`;

const Content = styled('main')`
  display: flex;
  flex-grow: 1;
  margin: 0px 88px;
  padding-top: 3rem;
  background: ${({ theme }) => theme.colors.background};

  table tr {
    background: ${({ theme }) => theme.colors.background};
  }

  @media only screen and (max-width: 1023px) {
    padding-left: 0;
    margin: 0 10px;
    padding-top: 3rem;
  }
`;

const MaxWidth = styled('div')`
  @media only screen and (max-width: 50rem) {
    width: 100%;
    position: relative;
  }
`;

const LeftSideBarWidth = styled('div')`
  width: 298px;
  background-color: ${({ theme, isDarkThemeActive }) => {
    const value = theme.colors.background === '#fff' ? '#F4F8FB' : theme.colors.background;
    console.log('value is ');
    console.log(isDarkThemeActive);
    console.log(value);
    return value;
  }};
`;

const RightSideBarWidth = styled('div')`
  width: 224px;
`;

const Layout = ({ children, location, isDarkThemeActive, theme, pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;
  const customCrumbLabel = location.pathname.toLowerCase().replace('s', ' a ');
  React.useEffect(() => {
    console.log('in layout');
    console.log(isDarkThemeActive);
    console.log(theme);
    console.log('pageContext');
    console.log(pageContext);
    console.log('data');
    console.log(data.allMdx.edges);
    console.log('crumbs');
    console.log(crumbs);
  }, [isDarkThemeActive, theme, pageContext]);
  return (
    <ThemeProvider location={location}>
      <MDXProvider components={mdxComponents}>
        <Wrapper>
          <LeftSideBarWidth className={'hiddenMobile'}>
            <Sidebar location={location} />
          </LeftSideBarWidth>
          {config.sidebar.title ? (
            <div
              className={'sidebarTitle sideBarShow'}
              dangerouslySetInnerHTML={{ __html: config.sidebar.title }}
            />
          ) : null}
          <Content>
            <MaxWidth>
              <Breadcrumb
                crumbs={crumbs
                  .map((item, index) => {
                    if (!item.originalpath) item.originalpath = item.pathname;

                    item.crumbLabel = data.mdx.fields.title;
                    data.allMdx.edges.forEach((itemi) => {
                      const node = itemi.node.fields;

                      if (node.slug + '/' === item.originalpath) item.crumbLabel = node.title;
                    });

                    const tempArr = item.pathname.split('/');
                    if (
                      tempArr.length === 2 ||
                      (tempArr.length === 3 && tempArr[2].trim().length < 2)
                    ) {
                      if (index + 1 <= crumbs.length - 1) {
                        console.log('helio');
                        console.log(crumbs);
                        console.log(index);
                        console.log(item);
                        console.log(crumbs[index]);
                        // item.pathname = crumbs[index + 1].pathname;
                        item.pathname = './';
                      }
                    }
                    return item;
                  })
                  .slice(1)}
                crumbSeparator={
                  <>
                    &nbsp; <b>&gt;</b> &nbsp;
                  </>
                }
                // crumbLabel={data.mdx.fields.title}
                // customCrumbLabel={customCrumbLabel}
              />
              <br></br>
              {children}
            </MaxWidth>
          </Content>
          <RightSideBarWidth className={'hiddenMobile'}>
            <RightSidebar location={location} />
          </RightSideBarWidth>
        </Wrapper>
      </MDXProvider>
    </ThemeProvider>
  );
};

export default Layout;
