import React, { Component } from 'react';

class TabsContent extends Component {
  render() {
    return (
      <div className={`tabs__content ${this.props.activeTab == this.props.tabId ? 'active' : ''}`}>
        <p>{this.props.children}</p>
      </div>
    );
  }
}

export default TabsContent;
