export const languagesCodes = {
  gettingStartedCode: {
    languages: {
      JSON: {
        language: 'javascript',
        code: `{\r\n  data: {\r\n    name: 'Abhay',\r\n    email: 'email@email',\r\n    phone: '7666804987',\r\n    address: 'address 1',\r\n    products: [\r\n      {\r\n        product_sku_id: 'sku_9',\r\n        amount: '25',\r\n        qty: '1',\r\n      },\r\n      {\r\n        product_sku_id: 'sku_10',\r\n        amount: '35',\r\n        qty: '1',\r\n      },\r\n      {\r\n        product_sku_id: 'sku_13',\r\n        amount: '33',\r\n        qty: '1',\r\n      },\r\n      {\r\n        product_sku_id: 'sku_14',\r\n        amount: '105',\r\n        qty: '6',\r\n      },\r\n    ],\r\n    order_id: 'ID88',\r\n    total_amount: '215',\r\n    discount: '15',\r\n    total_qty: '9',\r\n    refferral_code: 'EV78G55H',\r\n    user_id: '88',\r\n    subscript_points: true,\r\n  },\r\n};\r\n`,
      },
      Javascript: {
        language: 'javascript',
        code: `function fun() {\r\n return 1;\r\n}\r\nfun();\r\n`,
      },
      'C++': {
        language: 'cpp',
        code: `#include<iostream>\r\nusing namespace std;\r\n\r\nint main(){\r\n cout<<\"hi this is cpp terminal\\n\";\r\n return 0;\r\n}`,
      },
      Python: {
        language: 'python',
        code: `def my_function():\r\n  print(\"Hello from a function\")\r\n\r\nmy_function()`,
      },
    },
  },
  // orderPredictionAPISampleRequest: {
  //   languages: {
  //     JSON: {
  //       language: 'json',
  //       code: `\/\/ GET \/api\/predictions\/orders HTTP\/1.1\r\n{\r\n  data: {\r\n    name: 'Abhay',\r\n    email: 'email@email',\r\n    phone: '7666804987',\r\n    address: 'address 1',\r\n    products: [\r\n      {\r\n        product_sku_id: 'sku_9',\r\n        amount: '25',\r\n        qty: '1',\r\n      },\r\n      {\r\n        product_sku_id: 'sku_10',\r\n        amount: '35',\r\n        qty: '1',\r\n      },\r\n      {\r\n        product_sku_id: 'sku_13',\r\n        amount: '33',\r\n        qty: '1',\r\n      },\r\n      {\r\n        product_sku_id: 'sku_14',\r\n        amount: '105',\r\n        qty: '6',\r\n      },\r\n    ],\r\n    order_id: 'ID88',\r\n    total_amount: '215',\r\n    discount: '15',\r\n    total_qty: '9',\r\n    refferral_code: 'EV78G55H',\r\n    user_id: '88',\r\n    subscript_points: true,\r\n  },\r\n};\r\n`,
  //     },
  //     Javascript: {
  //       language: 'javascript',
  //       code: `function fun() {\r\n return 1;\r\n}\r\nfun();\r\n`,
  //     },
  //     'C++': {
  //       language: 'cpp',
  //       code: `#include<iostream>\r\nusing namespace std;\r\n\r\nint main(){\r\n cout<<\"hi this is cpp terminal\\n\";\r\n return 0;\r\n}`,
  //     },
  //     Python: {
  //       language: 'python',
  //       code: `def my_function():\r\n  print(\"Hello from a function\")\r\n\r\nmy_function()`,
  //     },
  //   },
  // },
  // orderUpsertAPISampleRequest: {
  //   languages: {
  //     JSON: {
  //       language: 'json',
  //       code: `\/\/ POST \/api\/integration\/orders HTTP\/1.1\r\n{\r\n  data: [\r\n    {\r\n      name: 'Abhay',\r\n      email: 'email@email',\r\n      phone: '7666804987',\r\n      address: 'address 1',\r\n      products: [\r\n        {\r\n          product_sku_id: 'sss1',\r\n          amount: '25',\r\n          qty: '1',\r\n        },\r\n        {\r\n          product_sku_id: 'sss2',\r\n          amount: '25',\r\n          qty: '1',\r\n        },\r\n        {\r\n          product_sku_id: 'ss3s',\r\n          amount: '25',\r\n          qty: '1',\r\n        },\r\n        {\r\n          product_sku_id: 'sss4',\r\n          amount: '25',\r\n          qty: '1',\r\n        },\r\n      ],\r\n      order_id: 'ID-01',\r\n      total_amount: '5000',\r\n      discount: '15',\r\n      total_qty: '45',\r\n      refferral_code: 'EV78G55H',\r\n      user_id: '99',\r\n    },\r\n  ],\r\n};\r\n`,
  //     },
  //     Javascript: {
  //       language: 'javascript',
  //       code: `function fun() {\r\n return 1;\r\n}\r\nfun();\r\n`,
  //     },
  //     'C++': {
  //       language: 'cpp',
  //       code: `#include<iostream>\r\nusing namespace std;\r\n\r\nint main(){\r\n cout<<\"hi this is cpp terminal\\n\";\r\n return 0;\r\n}`,
  //     },
  //     Python: {
  //       language: 'python',
  //       code: `def my_function():\r\n  print(\"Hello from a function\")\r\n\r\nmy_function()`,
  //     },
  //   },
  // },
  // playerUpsertAPISampleRequest: {
  //   languages: {
  //     JSON: {
  //       language: 'json',
  //       code: `\/\/ POST \/api\/integration\/players HTTP\/1.1\r\n{\r\n  data: [\r\n    {\r\n      Name: 'abhay',\r\n      Email: 'slimin3am@gmail.com',\r\n      Phone: '7666804987',\r\n      Add: '',\r\n      DOB: '24-02-1994',\r\n      Gender: 'male',\r\n      Refercode: '',\r\n      State: 'Maharashtra',\r\n      District: 'Navi Mumbai',\r\n      RefBy: '',\r\n      Pincode: '400710',\r\n      Country: 'India',\r\n      User_id: '98',\r\n    },\r\n  ],\r\n};\r\n`,
  //     },
  //     Javascript: {
  //       language: 'javascript',
  //       code: `function fun() {\r\n return 1;\r\n}\r\nfun();\r\n`,
  //     },
  //     'C++': {
  //       language: 'cpp',
  //       code: `#include<iostream>\r\nusing namespace std;\r\n\r\nint main(){\r\n cout<<\"hi this is cpp terminal\\n\";\r\n return 0;\r\n}`,
  //     },
  //     Python: {
  //       language: 'python',
  //       code: `def my_function():\r\n  print(\"Hello from a function\")\r\n\r\nmy_function()`,
  //     },
  //   },
  // },
  // productUpsertAPISampleRequest: {
  //   languages: {
  //     cURL: {
  //       language: 'javascript',
  //       code: `
        
  //       `,
  //     },
  //     "C#-RestSharp": {
  //       language: 'csharp',
  //       code: `
        
  //       `,
  //     },
  //     Go: {
  //       language: 'go',
  //       code: `
        
  //       `,
  //     },
  //     "Java-OkHttp": {
  //       language: 'java',
  //       code: `
        
  //       `,
  //     },
  //     C: {
  //       language: 'c',
  //       code: `
        
  //       `,
  //     },
  //     "Node-Axios": {
  //       language: 'javascript',
  //       code: `
        
  //       `,
  //     },
  //     "PHP-cUrl": {
  //       language: 'php',
  //       code: `
        
  //       `,
  //     },
  //     PowerShell: {
  //       language: 'powershell',
  //       code: `
        
  //       `,
  //     },
  //     "Python-http.client": {
  //       language: 'python',
  //       code: `
        
  //       `,
  //     },
  //     Ruby: {
  //       language: 'ruby',
  //       code: `
        
  //       `,
  //     },
      
  //   },
  // },
  extras: {
    languages: {
      cURL: {
        language: 'javascript',
        code: `
        
        `,
      },
      "C#-RestSharp": {
        language: 'csharp',
        code: `
        
        `,
      },
      Go: {
        language: 'go',
        code: `
        
        `,
      },
      "Java-OkHttp": {
        language: 'java',
        code: `
        
        `,
      },
      C: {
        language: 'c',
        code: `
        
        `,
      },
      "Node-Axios": {
        language: 'javascript',
        code: `
        
        `,
      },
      "PHP-cUrl": {
        language: 'php',
        code: `
        
        `,
      },
      PowerShell: {
        language: 'powershell',
        code: `
        
        `,
      },
      "Python-http.client": {
        language: 'python',
        code: `
        
        `,
      },
      Ruby: {
        language: 'ruby',
        code: `
        
        `,
      },
      
    },
  },
  orderPredictionAPISampleRequest: {
    languages: {
      cURL: {
        language: 'javascript',
        code: `
curl --location --request GET 'localhost:8000/api/predictions/orders' \
--header 'token: U78FtRBfsMAnecq_q3i8J' \
--header 'Content-Type: application/json' \
--data-raw '{
    "data": {
        "name": "Abhay",
        "email": "email@email.com",
        "phone": "7666804987",
        "address": "address 1",
        "products": [
            {
                "product_sku_id": "sku_9",
                "amount": "25",
                "qty": "1"
            },
            {
                "product_sku_id": "sku_10",
                "amount": "35",
                "qty": "1"
            },
            {
                "product_sku_id": "sku_13",
                "amount": "33",
                "qty": "1"
            },
            {
                "product_sku_id": "sku_14",
                "amount": "105",
                "qty": "6"
            }
        ],
        "order_id": "ID88",
        "total_amount": "215",
        "discount": "15",
        "total_qty": "9",
        "refferral_code": "EV78G55H",
        "user_id": "88",
        "subscript_points": true
    }
}'
        `,
      },
      "PHP-cUrl": {
        language: 'php',
        code: `
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'localhost:8000/api/predictions/orders',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'GET',
  CURLOPT_POSTFIELDS =>'{
    "data": {
        "name": "Abhay",
        "email": "email@email.com",
        "phone": "7666804987",
        "address": "address 1",
        "products": [
            {
                "product_sku_id": "sku_9",
                "amount": "25",
                "qty": "1"
            },
            {
                "product_sku_id": "sku_10",
                "amount": "35",
                "qty": "1"
            },
            {
                "product_sku_id": "sku_13",
                "amount": "33",
                "qty": "1"
            },
            {
                "product_sku_id": "sku_14",
                "amount": "105",
                "qty": "6"
            }
        ],
        "order_id": "ID88",
        "total_amount": "215",
        "discount": "15",
        "total_qty": "9",
        "refferral_code": "EV78G55H",
        "user_id": "88",
        "subscript_points": true
    }
}',
  CURLOPT_HTTPHEADER => array(
    'token: U78FtRBfsMAnecq_q3i8J',
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;

        `,
      },
      "Node-Axios": {
        language: 'javascript',
        code: `
var axios = require('axios');
var data = JSON.stringify({
  "data": {
    "name": "Abhay",
    "email": "email@email.com",
    "phone": "7666804987",
    "address": "address 1",
    "products": [
      {
        "product_sku_id": "sku_9",
        "amount": "25",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_10",
        "amount": "35",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_13",
        "amount": "33",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_14",
        "amount": "105",
        "qty": "6"
      }
    ],
    "order_id": "ID88",
    "total_amount": "215",
    "discount": "15",
    "total_qty": "9",
    "refferral_code": "EV78G55H",
    "user_id": "88",
    "subscript_points": true
  }
});

var config = {
  method: 'get',
  url: 'localhost:8000/api/predictions/orders',
  headers: { 
    'token': 'U78FtRBfsMAnecq_q3i8J', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

        `,
      },
      "C#-RestSharp": {
        language: 'csharp',
        code: `
var client = new RestClient("localhost:8000/api/predictions/orders");
client.Timeout = -1;
var request = new RestRequest(Method.GET);
request.AddHeader("token", "U78FtRBfsMAnecq_q3i8J");
request.AddHeader("Content-Type", "application/json");
var body = @"{
" + "\n" +
@"    ""data"": {
" + "\n" +
@"        ""name"": ""Abhay"",
" + "\n" +
@"        ""email"": ""email@email.com"",
" + "\n" +
@"        ""phone"": ""7666804987"",
" + "\n" +
@"        ""address"": ""address 1"",
" + "\n" +
@"        ""products"": [
" + "\n" +
@"            {
" + "\n" +
@"                ""product_sku_id"": ""sku_9"",
" + "\n" +
@"                ""amount"": ""25"",
" + "\n" +
@"                ""qty"": ""1""
" + "\n" +
@"            },
" + "\n" +
@"            {
" + "\n" +
@"                ""product_sku_id"": ""sku_10"",
" + "\n" +
@"                ""amount"": ""35"",
" + "\n" +
@"                ""qty"": ""1""
" + "\n" +
@"            },
" + "\n" +
@"            {
" + "\n" +
@"                ""product_sku_id"": ""sku_13"",
" + "\n" +
@"                ""amount"": ""33"",
" + "\n" +
@"                ""qty"": ""1""
" + "\n" +
@"            },
" + "\n" +
@"            {
" + "\n" +
@"                ""product_sku_id"": ""sku_14"",
" + "\n" +
@"                ""amount"": ""105"",
" + "\n" +
@"                ""qty"": ""6""
" + "\n" +
@"            }
" + "\n" +
@"        ],
" + "\n" +
@"        ""order_id"": ""ID88"",
" + "\n" +
@"        ""total_amount"": ""215"",
" + "\n" +
@"        ""discount"": ""15"",
" + "\n" +
@"        ""total_qty"": ""9"",
" + "\n" +
@"        ""refferral_code"": ""EV78G55H"",
" + "\n" +
@"        ""user_id"": ""88"",
" + "\n" +
@"        ""subscript_points"": true
" + "\n" +
@"    }
" + "\n" +
@"}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);
        `,
      },
      
      "Python-http.client": {
        language: 'python',
        code: `
import http.client
import json

conn = http.client.HTTPSConnection("localhost", undefined)
payload = json.dumps({
  "data": {
    "name": "Abhay",
    "email": "email@email.com",
    "phone": "7666804987",
    "address": "address 1",
    "products": [
      {
        "product_sku_id": "sku_9",
        "amount": "25",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_10",
        "amount": "35",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_13",
        "amount": "33",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_14",
        "amount": "105",
        "qty": "6"
      }
    ],
    "order_id": "ID88",
    "total_amount": "215",
    "discount": "15",
    "total_qty": "9",
    "refferral_code": "EV78G55H",
    "user_id": "88",
    "subscript_points": True
  }
})
headers = {
  'token': 'U78FtRBfsMAnecq_q3i8J',
  'Content-Type': 'application/json'
}
conn.request("GET", "/api/predictions/orders", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
        `,
      },
      "Java-OkHttp": {
        language: 'java',
        code: `
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
Request request = new Request.Builder()
  .url("localhost:8000/api/predictions/orders")
  .method("GET", null)
  .addHeader("token", "U78FtRBfsMAnecq_q3i8J")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
        `,
      },
      Go: {
        language: 'go',
        code: `
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "localhost:8000/api/predictions/orders"
  method := "GET"

  payload := strings.NewReader(\`{\`+"
"+\`
    "data": {\`+"
"+\`
        "name": "Abhay",\`+"
"+\`
        "email": "email@email.com",\`+"
"+\`
        "phone": "7666804987",\`+"
"+\`
        "address": "address 1",\`+"
"+\`
        "products": [\`+"
"+\`
            {\`+"
"+\`
                "product_sku_id": "sku_9",\`+"
"+\`
                "amount": "25",\`+"
"+\`
                "qty": "1"\`+"
"+\`
            },\`+"
"+\`
            {\`+"
"+\`
                "product_sku_id": "sku_10",\`+"
"+\`
                "amount": "35",\`+"
"+\`
                "qty": "1"\`+"
"+\`
            },\`+"
"+\`
            {\`+"
"+\`
                "product_sku_id": "sku_13",\`+"
"+\`
                "amount": "33",\`+"
"+\`
                "qty": "1"\`+"
"+\`
            },\`+"
"+\`
            {\`+"
"+\`
                "product_sku_id": "sku_14",\`+"
"+\`
                "amount": "105",\`+"
"+\`
                "qty": "6"\`+"
"+\`
            }\`+"
"+\`
        ],\`+"
"+\`
        "order_id": "ID88",\`+"
"+\`
        "total_amount": "215",\`+"
"+\`
        "discount": "15",\`+"
"+\`
        "total_qty": "9",\`+"
"+\`
        "refferral_code": "EV78G55H",\`+"
"+\`
        "user_id": "88",\`+"
"+\`
        "subscript_points": true\`+"
"+\`
    }\`+"
"+\`
}\`)

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("token", "U78FtRBfsMAnecq_q3i8J")
  req.Header.Add("Content-Type", "application/json")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
        `,
      },
      Ruby: {
        language: 'ruby',
        code: `
require "uri"
require "json"
require "net/http"

url = URI("localhost:8000/api/predictions/orders")

http = Net::HTTP.new(url.host, url.port);
request = Net::HTTP::Get.new(url)
request["token"] = "U78FtRBfsMAnecq_q3i8J"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "data": {
    "name": "Abhay",
    "email": "email@email.com",
    "phone": "7666804987",
    "address": "address 1",
    "products": [
      {
        "product_sku_id": "sku_9",
        "amount": "25",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_10",
        "amount": "35",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_13",
        "amount": "33",
        "qty": "1"
      },
      {
        "product_sku_id": "sku_14",
        "amount": "105",
        "qty": "6"
      }
    ],
    "order_id": "ID88",
    "total_amount": "215",
    "discount": "15",
    "total_qty": "9",
    "refferral_code": "EV78G55H",
    "user_id": "88",
    "subscript_points": true
  }
})

response = http.request(request)
puts response.read_body

        `,
      },
      C: {
        language: 'c',
        code: `
CURL *curl;
CURLcode res;
curl = curl_easy_init();
if(curl) {
  curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, "GET");
  curl_easy_setopt(curl, CURLOPT_URL, "localhost:8000/api/predictions/orders");
  curl_easy_setopt(curl, CURLOPT_FOLLOWLOCATION, 1L);
  curl_easy_setopt(curl, CURLOPT_DEFAULT_PROTOCOL, "https");
  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "token: U78FtRBfsMAnecq_q3i8J");
  headers = curl_slist_append(headers, "Content-Type: application/json");
  curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
  const char *data = "{\r\n    \"data\": {\r\n        \"name\": \"Abhay\",\r\n        \"email\": \"email@email.com\",\r\n        \"phone\": \"7666804987\",\r\n        \"address\": \"address 1\",\r\n        \"products\": [\r\n            {\r\n                \"product_sku_id\": \"sku_9\",\r\n                \"amount\": \"25\",\r\n                \"qty\": \"1\"\r\n            },\r\n            {\r\n                \"product_sku_id\": \"sku_10\",\r\n                \"amount\": \"35\",\r\n                \"qty\": \"1\"\r\n            },\r\n            {\r\n                \"product_sku_id\": \"sku_13\",\r\n                \"amount\": \"33\",\r\n                \"qty\": \"1\"\r\n            },\r\n            {\r\n                \"product_sku_id\": \"sku_14\",\r\n                \"amount\": \"105\",\r\n                \"qty\": \"6\"\r\n            }\r\n        ],\r\n        \"order_id\": \"ID88\",\r\n        \"total_amount\": \"215\",\r\n        \"discount\": \"15\",\r\n        \"total_qty\": \"9\",\r\n        \"refferral_code\": \"EV78G55H\",\r\n        \"user_id\": \"88\",\r\n        \"subscript_points\": true\r\n    }\r\n}";
  curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
  res = curl_easy_perform(curl);
}
curl_easy_cleanup(curl);

        `,
      },
      PowerShell: {
        language: 'powershell',
        code: `
$headers = New-Object "System.Collections.Generic.Dictionary[[String],[String]]"
$headers.Add("token", "U78FtRBfsMAnecq_q3i8J")
$headers.Add("Content-Type", "application/json")

$body = "{
\`n    \`"data\`": {
\`n        \`"name\`": \`"Abhay\`",
\`n        \`"email\`": \`"email@email.com\`",
\`n        \`"phone\`": \`"7666804987\`",
\`n        \`"address\`": \`"address 1\`",
\`n        \`"products\`": [
\`n            {
\`n                \`"product_sku_id\`": \`"sku_9\`",
\`n                \`"amount\`": \`"25\`",
\`n                \`"qty\`": \`"1\`"
\`n            },
\`n            {
\`n                \`"product_sku_id\`": \`"sku_10\`",
\`n                \`"amount\`": \`"35\`",
\`n                \`"qty\`": \`"1\`"
\`n            },
\`n            {
\`n                \`"product_sku_id\`": \`"sku_13\`",
\`n                \`"amount\`": \`"33\`",
\`n                \`"qty\`": \`"1\`"
\`n            },
\`n            {
\`n                \`"product_sku_id\`": \`"sku_14\`",
\`n                \`"amount\`": \`"105\`",
\`n                \`"qty\`": \`"6\`"
\`n            }
\`n        ],
\`n        \`"order_id\`": \`"ID88\`",
\`n        \`"total_amount\`": \`"215\`",
\`n        \`"discount\`": \`"15\`",
\`n        \`"total_qty\`": \`"9\`",
\`n        \`"refferral_code\`": \`"EV78G55H\`",
\`n        \`"user_id\`": \`"88\`",
\`n        \`"subscript_points\`": true
\`n    }
\`n}"

$response = Invoke-RestMethod 'localhost:8000/api/predictions/orders' -Method 'GET' -Headers $headers -Body $body
$response | ConvertTo-Json
        `,
      },
      
      
      
    },
  },
  orderUpsertAPISampleRequest: {
    languages: {
      cURL: {
        language: 'javascript',
        code: `
curl --location --request POST 'localhost:8000/api/integration/orders' \
--header 'token: U78FtRBfsMAnecq_q3i8J' \
--header 'Content-Type: application/json' \
--data-raw '{
    "data": [
        {
            "name": "Abhay",
            "email": "email@email.com",
            "phone": "7666804987",
            "address": "address 1",
            "products": [
                {
                    "product_sku_id": "sss1",
                    "amount": "25",
                    "qty": "1"
                },
                {
                    "product_sku_id": "sss2",
                    "amount": "25",
                    "qty": "1"
                },
                {
                    "product_sku_id": "ss3s",
                    "amount": "25",
                    "qty": "1"
                },
                {
                    "product_sku_id": "sss4",
                    "amount": "25",
                    "qty": "1"
                }
            ],
            "order_id": "ID-01",
            "total_amount": "5000",
            "discount": "15",
            "total_qty": "45",
            "refferral_code": "EV78G55H",
            "user_id": "99"
        }
    ]
}'
        `,
      },
      "PHP-cUrl": {
        language: 'php',
        code: `
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'localhost:8000/api/integration/orders',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "data": [
        {
            "name": "Abhay",
            "email": "email@email.com",
            "phone": "7666804987",
            "address": "address 1",
            "products": [
                {
                    "product_sku_id": "sss1",
                    "amount": "25",
                    "qty": "1"
                },
                {
                    "product_sku_id": "sss2",
                    "amount": "25",
                    "qty": "1"
                },
                {
                    "product_sku_id": "ss3s",
                    "amount": "25",
                    "qty": "1"
                },
                {
                    "product_sku_id": "sss4",
                    "amount": "25",
                    "qty": "1"
                }
            ],
            "order_id": "ID-01",
            "total_amount": "5000",
            "discount": "15",
            "total_qty": "45",
            "refferral_code": "EV78G55H",
            "user_id": "99"
        }
    ]
}',
  CURLOPT_HTTPHEADER => array(
    'token: U78FtRBfsMAnecq_q3i8J',
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;

        `,
      },
      "Node-Axios": {
        language: 'javascript',
        code: `
var axios = require('axios');
var data = JSON.stringify({
  "data": [
    {
      "name": "Abhay",
      "email": "email@email.com",
      "phone": "7666804987",
      "address": "address 1",
      "products": [
        {
          "product_sku_id": "sss1",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "sss2",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "ss3s",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "sss4",
          "amount": "25",
          "qty": "1"
        }
      ],
      "order_id": "ID-01",
      "total_amount": "5000",
      "discount": "15",
      "total_qty": "45",
      "refferral_code": "EV78G55H",
      "user_id": "99"
    }
  ]
});

var config = {
  method: 'post',
  url: 'localhost:8000/api/integration/orders',
  headers: { 
    'token': 'U78FtRBfsMAnecq_q3i8J', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

        `,
      },
      "C#-RestSharp": {
        language: 'csharp',
        code: `
var client = new RestClient("localhost:8000/api/integration/orders");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("token", "U78FtRBfsMAnecq_q3i8J");
request.AddHeader("Content-Type", "application/json");
var body = @"{
" + "\n" +
@"    ""data"": [
" + "\n" +
@"        {
" + "\n" +
@"            ""name"": ""Abhay"",
" + "\n" +
@"            ""email"": ""email@email.com"",
" + "\n" +
@"            ""phone"": ""7666804987"",
" + "\n" +
@"            ""address"": ""address 1"",
" + "\n" +
@"            ""products"": [
" + "\n" +
@"                {
" + "\n" +
@"                    ""product_sku_id"": ""sss1"",
" + "\n" +
@"                    ""amount"": ""25"",
" + "\n" +
@"                    ""qty"": ""1""
" + "\n" +
@"                },
" + "\n" +
@"                {
" + "\n" +
@"                    ""product_sku_id"": ""sss2"",
" + "\n" +
@"                    ""amount"": ""25"",
" + "\n" +
@"                    ""qty"": ""1""
" + "\n" +
@"                },
" + "\n" +
@"                {
" + "\n" +
@"                    ""product_sku_id"": ""ss3s"",
" + "\n" +
@"                    ""amount"": ""25"",
" + "\n" +
@"                    ""qty"": ""1""
" + "\n" +
@"                },
" + "\n" +
@"                {
" + "\n" +
@"                    ""product_sku_id"": ""sss4"",
" + "\n" +
@"                    ""amount"": ""25"",
" + "\n" +
@"                    ""qty"": ""1""
" + "\n" +
@"                }
" + "\n" +
@"            ],
" + "\n" +
@"            ""order_id"": ""ID-01"",
" + "\n" +
@"            ""total_amount"": ""5000"",
" + "\n" +
@"            ""discount"": ""15"",
" + "\n" +
@"            ""total_qty"": ""45"",
" + "\n" +
@"            ""refferral_code"": ""EV78G55H"",
" + "\n" +
@"            ""user_id"": ""99""
" + "\n" +
@"        }
" + "\n" +
@"    ]
" + "\n" +
@"}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);
        `,
      },      
      "Python-http.client": {
        language: 'python',
        code: `
import http.client
import json

conn = http.client.HTTPSConnection("localhost", undefined)
payload = json.dumps({
  "data": [
    {
      "name": "Abhay",
      "email": "email@email.com",
      "phone": "7666804987",
      "address": "address 1",
      "products": [
        {
          "product_sku_id": "sss1",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "sss2",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "ss3s",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "sss4",
          "amount": "25",
          "qty": "1"
        }
      ],
      "order_id": "ID-01",
      "total_amount": "5000",
      "discount": "15",
      "total_qty": "45",
      "refferral_code": "EV78G55H",
      "user_id": "99"
    }
  ]
})
headers = {
  'token': 'U78FtRBfsMAnecq_q3i8J',
  'Content-Type': 'application/json'
}
conn.request("POST", "/api/integration/orders", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
        `,
      },
      "Java-OkHttp": {
        language: 'java',
        code: `
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n    \"data\": [\r\n        {\r\n            \"name\": \"Abhay\",\r\n            \"email\": \"email@email.com\",\r\n            \"phone\": \"7666804987\",\r\n            \"address\": \"address 1\",\r\n            \"products\": [\r\n                {\r\n                    \"product_sku_id\": \"sss1\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                },\r\n                {\r\n                    \"product_sku_id\": \"sss2\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                },\r\n                {\r\n                    \"product_sku_id\": \"ss3s\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                },\r\n                {\r\n                    \"product_sku_id\": \"sss4\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                }\r\n            ],\r\n            \"order_id\": \"ID-01\",\r\n            \"total_amount\": \"5000\",\r\n            \"discount\": \"15\",\r\n            \"total_qty\": \"45\",\r\n            \"refferral_code\": \"EV78G55H\",\r\n            \"user_id\": \"99\"\r\n        }\r\n    ]\r\n}");
Request request = new Request.Builder()
  .url("localhost:8000/api/integration/orders")
  .method("POST", body)
  .addHeader("token", "U78FtRBfsMAnecq_q3i8J")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
        `,
      },
      Go: {
        language: 'go',
        code: `
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "localhost:8000/api/integration/orders"
  method := "POST"

  payload := strings.NewReader(\`{\`+"
"+\`
    "data": [\`+"
"+\`
        {\`+"
"+\`
            "name": "Abhay",\`+"
"+\`
            "email": "email@email.com",\`+"
"+\`
            "phone": "7666804987",\`+"
"+\`
            "address": "address 1",\`+"
"+\`
            "products": [\`+"
"+\`
                {\`+"
"+\`
                    "product_sku_id": "sss1",\`+"
"+\`
                    "amount": "25",\`+"
"+\`
                    "qty": "1"\`+"
"+\`
                },\`+"
"+\`
                {\`+"
"+\`
                    "product_sku_id": "sss2",\`+"
"+\`
                    "amount": "25",\`+"
"+\`
                    "qty": "1"\`+"
"+\`
                },\`+"
"+\`
                {\`+"
"+\`
                    "product_sku_id": "ss3s",\`+"
"+\`
                    "amount": "25",\`+"
"+\`
                    "qty": "1"\`+"
"+\`
                },\`+"
"+\`
                {\`+"
"+\`
                    "product_sku_id": "sss4",\`+"
"+\`
                    "amount": "25",\`+"
"+\`
                    "qty": "1"\`+"
"+\`
                }\`+"
"+\`
            ],\`+"
"+\`
            "order_id": "ID-01",\`+"
"+\`
            "total_amount": "5000",\`+"
"+\`
            "discount": "15",\`+"
"+\`
            "total_qty": "45",\`+"
"+\`
            "refferral_code": "EV78G55H",\`+"
"+\`
            "user_id": "99"\`+"
"+\`
        }\`+"
"+\`
    ]\`+"
"+\`
}\`)

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("token", "U78FtRBfsMAnecq_q3i8J")
  req.Header.Add("Content-Type", "application/json")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
        `,
      },
      
      Ruby: {
        language: 'ruby',
        code: `
require "uri"
require "json"
require "net/http"

url = URI("localhost:8000/api/integration/orders")

http = Net::HTTP.new(url.host, url.port);
request = Net::HTTP::Post.new(url)
request["token"] = "U78FtRBfsMAnecq_q3i8J"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "data": [
    {
      "name": "Abhay",
      "email": "email@email.com",
      "phone": "7666804987",
      "address": "address 1",
      "products": [
        {
          "product_sku_id": "sss1",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "sss2",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "ss3s",
          "amount": "25",
          "qty": "1"
        },
        {
          "product_sku_id": "sss4",
          "amount": "25",
          "qty": "1"
        }
      ],
      "order_id": "ID-01",
      "total_amount": "5000",
      "discount": "15",
      "total_qty": "45",
      "refferral_code": "EV78G55H",
      "user_id": "99"
    }
  ]
})

response = http.request(request)
puts response.read_body

        `,
      },
      C: {
        language: 'c',
        code: `
CURL *curl;
CURLcode res;
curl = curl_easy_init();
if(curl) {
  curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, "POST");
  curl_easy_setopt(curl, CURLOPT_URL, "localhost:8000/api/integration/orders");
  curl_easy_setopt(curl, CURLOPT_FOLLOWLOCATION, 1L);
  curl_easy_setopt(curl, CURLOPT_DEFAULT_PROTOCOL, "https");
  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "token: U78FtRBfsMAnecq_q3i8J");
  headers = curl_slist_append(headers, "Content-Type: application/json");
  curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
  const char *data = "{\r\n    \"data\": [\r\n        {\r\n            \"name\": \"Abhay\",\r\n            \"email\": \"email@email.com\",\r\n            \"phone\": \"7666804987\",\r\n            \"address\": \"address 1\",\r\n            \"products\": [\r\n                {\r\n                    \"product_sku_id\": \"sss1\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                },\r\n                {\r\n                    \"product_sku_id\": \"sss2\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                },\r\n                {\r\n                    \"product_sku_id\": \"ss3s\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                },\r\n                {\r\n                    \"product_sku_id\": \"sss4\",\r\n                    \"amount\": \"25\",\r\n                    \"qty\": \"1\"\r\n                }\r\n            ],\r\n            \"order_id\": \"ID-01\",\r\n            \"total_amount\": \"5000\",\r\n            \"discount\": \"15\",\r\n            \"total_qty\": \"45\",\r\n            \"refferral_code\": \"EV78G55H\",\r\n            \"user_id\": \"99\"\r\n        }\r\n    ]\r\n}";
  curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
  res = curl_easy_perform(curl);
}
curl_easy_cleanup(curl);

        `,
      },
      PowerShell: {
        language: 'powershell',
        code: `
$headers = New-Object "System.Collections.Generic.Dictionary[[String],[String]]"
$headers.Add("token", "U78FtRBfsMAnecq_q3i8J")
$headers.Add("Content-Type", "application/json")

$body = "{
\`n    \`"data\`": [
\`n        {
\`n            \`"name\`": \`"Abhay\`",
\`n            \`"email\`": \`"email@email.com\`",
\`n            \`"phone\`": \`"7666804987\`",
\`n            \`"address\`": \`"address 1\`",
\`n            \`"products\`": [
\`n                {
\`n                    \`"product_sku_id\`": \`"sss1\`",
\`n                    \`"amount\`": \`"25\`",
\`n                    \`"qty\`": \`"1\`"
\`n                },
\`n                {
\`n                    \`"product_sku_id\`": \`"sss2\`",
\`n                    \`"amount\`": \`"25\`",
\`n                    \`"qty\`": \`"1\`"
\`n                },
\`n                {
\`n                    \`"product_sku_id\`": \`"ss3s\`",
\`n                    \`"amount\`": \`"25\`",
\`n                    \`"qty\`": \`"1\`"
\`n                },
\`n                {
\`n                    \`"product_sku_id\`": \`"sss4\`",
\`n                    \`"amount\`": \`"25\`",
\`n                    \`"qty\`": \`"1\`"
\`n                }
\`n            ],
\`n            \`"order_id\`": \`"ID-01\`",
\`n            \`"total_amount\`": \`"5000\`",
\`n            \`"discount\`": \`"15\`",
\`n            \`"total_qty\`": \`"45\`",
\`n            \`"refferral_code\`": \`"EV78G55H\`",
\`n            \`"user_id\`": \`"99\`"
\`n        }
\`n    ]
\`n}"

$response = Invoke-RestMethod 'localhost:8000/api/integration/orders' -Method 'POST' -Headers $headers -Body $body
$response | ConvertTo-Json
        `,
      },
      
    },
  },
  playerUpsertAPISampleRequest: {
    languages: {
      cURL: {
        language: 'javascript',
        code: `
curl --location --request POST 'localhost:8000/api/integration/players' \
--header 'token: U78FtRBfsMAnecq_q3i8J' \
--header 'Content-Type: application/json' \
--data-raw '{
    "data": [
        {
            "Name": "abhay",
            "Email": "slimin3am@gmail.com",
            "Phone": "7666804987",
            "Add": "",
            "DOB": "24-02-1994",
            "Gender": "male",
            "Refercode": "",
            "State": "Maharashtra",
            "District": "Navi Mumbai",
            "RefBy": "",
            "Pincode": "400710",
            "Country": "India",
            "User_id": "98"
        }
    ]
}'
        `,
      },
      "PHP-cUrl": {
        language: 'php',
        code: `
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'localhost:8000/api/integration/players',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "data": [
        {
            "Name": "abhay",
            "Email": "slimin3am@gmail.com",
            "Phone": "7666804987",
            "Add": "",
            "DOB": "24-02-1994",
            "Gender": "male",
            "Refercode": "",
            "State": "Maharashtra",
            "District": "Navi Mumbai",
            "RefBy": "",
            "Pincode": "400710",
            "Country": "India",
            "User_id": "98"
        }
    ]
}',
  CURLOPT_HTTPHEADER => array(
    'token: U78FtRBfsMAnecq_q3i8J',
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;

        `,
      },
      "Node-Axios": {
        language: 'javascript',
        code: `
var axios = require('axios');
var data = JSON.stringify({
  "data": [
    {
      "Name": "abhay",
      "Email": "slimin3am@gmail.com",
      "Phone": "7666804987",
      "Add": "",
      "DOB": "24-02-1994",
      "Gender": "male",
      "Refercode": "",
      "State": "Maharashtra",
      "District": "Navi Mumbai",
      "RefBy": "",
      "Pincode": "400710",
      "Country": "India",
      "User_id": "98"
    }
  ]
});

var config = {
  method: 'post',
  url: 'localhost:8000/api/integration/players',
  headers: { 
    'token': 'U78FtRBfsMAnecq_q3i8J', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

        `,
      },
      "C#-RestSharp": {
        language: 'csharp',
        code: `
var client = new RestClient("localhost:8000/api/integration/players");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("token", "U78FtRBfsMAnecq_q3i8J");
request.AddHeader("Content-Type", "application/json");
var body = @"{
" + "\n" +
@"    ""data"": [
" + "\n" +
@"        {
" + "\n" +
@"            ""Name"": ""abhay"",
" + "\n" +
@"            ""Email"": ""slimin3am@gmail.com"",
" + "\n" +
@"            ""Phone"": ""7666804987"",
" + "\n" +
@"            ""Add"": """",
" + "\n" +
@"            ""DOB"": ""24-02-1994"",
" + "\n" +
@"            ""Gender"": ""male"",
" + "\n" +
@"            ""Refercode"": """",
" + "\n" +
@"            ""State"": ""Maharashtra"",
" + "\n" +
@"            ""District"": ""Navi Mumbai"",
" + "\n" +
@"            ""RefBy"": """",
" + "\n" +
@"            ""Pincode"": ""400710"",
" + "\n" +
@"            ""Country"": ""India"",
" + "\n" +
@"            ""User_id"": ""98""
" + "\n" +
@"        }
" + "\n" +
@"    ]
" + "\n" +
@"}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);
        `,
      },
      "Python-http.client": {
        language: 'python',
        code: `
import http.client
import json

conn = http.client.HTTPSConnection("localhost", undefined)
payload = json.dumps({
  "data": [
    {
      "Name": "abhay",
      "Email": "slimin3am@gmail.com",
      "Phone": "7666804987",
      "Add": "",
      "DOB": "24-02-1994",
      "Gender": "male",
      "Refercode": "",
      "State": "Maharashtra",
      "District": "Navi Mumbai",
      "RefBy": "",
      "Pincode": "400710",
      "Country": "India",
      "User_id": "98"
    }
  ]
})
headers = {
  'token': 'U78FtRBfsMAnecq_q3i8J',
  'Content-Type': 'application/json'
}
conn.request("POST", "/api/integration/players", payload, headers)
res = conn.getresponse()
data = res.read()
print(data.decode("utf-8"))
        `,
      },
      "Java-OkHttp": {
        language: 'java',
        code: `
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n    \"data\": [\r\n        {\r\n            \"Name\": \"abhay\",\r\n            \"Email\": \"slimin3am@gmail.com\",\r\n            \"Phone\": \"7666804987\",\r\n            \"Add\": \"\",\r\n            \"DOB\": \"24-02-1994\",\r\n            \"Gender\": \"male\",\r\n            \"Refercode\": \"\",\r\n            \"State\": \"Maharashtra\",\r\n            \"District\": \"Navi Mumbai\",\r\n            \"RefBy\": \"\",\r\n            \"Pincode\": \"400710\",\r\n            \"Country\": \"India\",\r\n            \"User_id\": \"98\"\r\n        }\r\n    ]\r\n}");
Request request = new Request.Builder()
  .url("localhost:8000/api/integration/players")
  .method("POST", body)
  .addHeader("token", "U78FtRBfsMAnecq_q3i8J")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
        `,
      },
      Go: {
        language: 'go',
        code: `
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "localhost:8000/api/integration/players"
  method := "POST"

  payload := strings.NewReader(\`{\`+"
"+\`
    "data": [\`+"
"+\`
        {\`+"
"+\`
            "Name": "abhay",\`+"
"+\`
            "Email": "slimin3am@gmail.com",\`+"
"+\`
            "Phone": "7666804987",\`+"
"+\`
            "Add": "",\`+"
"+\`
            "DOB": "24-02-1994",\`+"
"+\`
            "Gender": "male",\`+"
"+\`
            "Refercode": "",\`+"
"+\`
            "State": "Maharashtra",\`+"
"+\`
            "District": "Navi Mumbai",\`+"
"+\`
            "RefBy": "",\`+"
"+\`
            "Pincode": "400710",\`+"
"+\`
            "Country": "India",\`+"
"+\`
            "User_id": "98"\`+"
"+\`
        }\`+"
"+\`
    ]\`+"
"+\`
}\`)

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("token", "U78FtRBfsMAnecq_q3i8J")
  req.Header.Add("Content-Type", "application/json")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
        `,
      },
      Ruby: {
        language: 'ruby',
        code: `
require "uri"
require "json"
require "net/http"

url = URI("localhost:8000/api/integration/players")

http = Net::HTTP.new(url.host, url.port);
request = Net::HTTP::Post.new(url)
request["token"] = "U78FtRBfsMAnecq_q3i8J"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "data": [
    {
      "Name": "abhay",
      "Email": "slimin3am@gmail.com",
      "Phone": "7666804987",
      "Add": "",
      "DOB": "24-02-1994",
      "Gender": "male",
      "Refercode": "",
      "State": "Maharashtra",
      "District": "Navi Mumbai",
      "RefBy": "",
      "Pincode": "400710",
      "Country": "India",
      "User_id": "98"
    }
  ]
})

response = http.request(request)
puts response.read_body

        `,
      },
      C: {
        language: 'c',
        code: `
CURL *curl;
CURLcode res;
curl = curl_easy_init();
if(curl) {
  curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, "POST");
  curl_easy_setopt(curl, CURLOPT_URL, "localhost:8000/api/integration/players");
  curl_easy_setopt(curl, CURLOPT_FOLLOWLOCATION, 1L);
  curl_easy_setopt(curl, CURLOPT_DEFAULT_PROTOCOL, "https");
  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "token: U78FtRBfsMAnecq_q3i8J");
  headers = curl_slist_append(headers, "Content-Type: application/json");
  curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
  const char *data = "{\r\n    \"data\": [\r\n        {\r\n            \"Name\": \"abhay\",\r\n            \"Email\": \"slimin3am@gmail.com\",\r\n            \"Phone\": \"7666804987\",\r\n            \"Add\": \"\",\r\n            \"DOB\": \"24-02-1994\",\r\n            \"Gender\": \"male\",\r\n            \"Refercode\": \"\",\r\n            \"State\": \"Maharashtra\",\r\n            \"District\": \"Navi Mumbai\",\r\n            \"RefBy\": \"\",\r\n            \"Pincode\": \"400710\",\r\n            \"Country\": \"India\",\r\n            \"User_id\": \"98\"\r\n        }\r\n    ]\r\n}";
  curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
  res = curl_easy_perform(curl);
}
curl_easy_cleanup(curl);

        `,
      },
      PowerShell: {
        language: 'powershell',
        code: `
$headers = New-Object "System.Collections.Generic.Dictionary[[String],[String]]"
$headers.Add("token", "U78FtRBfsMAnecq_q3i8J")
$headers.Add("Content-Type", "application/json")

$body = "{
\`n    \`"data\`": [
\`n        {
\`n            \`"Name\`": \`"abhay\`",
\`n            \`"Email\`": \`"slimin3am@gmail.com\`",
\`n            \`"Phone\`": \`"7666804987\`",
\`n            \`"Add\`": \`"\`",
\`n            \`"DOB\`": \`"24-02-1994\`",
\`n            \`"Gender\`": \`"male\`",
\`n            \`"Refercode\`": \`"\`",
\`n            \`"State\`": \`"Maharashtra\`",
\`n            \`"District\`": \`"Navi Mumbai\`",
\`n            \`"RefBy\`": \`"\`",
\`n            \`"Pincode\`": \`"400710\`",
\`n            \`"Country\`": \`"India\`",
\`n            \`"User_id\`": \`"98\`"
\`n        }
\`n    ]
\`n}"

$response = Invoke-RestMethod 'localhost:8000/api/integration/players' -Method 'POST' -Headers $headers -Body $body
$response | ConvertTo-Json
        `,
      },
    },
  },
  productUpsertAPISampleRequest: {
    languages: {
      cURL: {
        language: 'javascript',
        code: `
curl --location --request POST 'localhost:8000/api/integration/products' \
--header 'token: U78FtRBfsMAnecq_q3i8J' \
--header 'Content-Type: application/json' \
--data-raw '{
    "data": [
        {
            "product_sku_id": "sku_1",
            "amount": "25"
        },
        {
            "product_sku_id": "sku_2",
            "amount": "25"
        },
        {
            "product_sku_id": "sku_3",
            "amount": "25"
        },
        {
            "product_sku_id": "sku_4",
            "amount": "25"
        }
    ]
}'
        `,
      },
      "PHP-cUrl": {
        language: 'php',
        code: `
<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => 'localhost:8000/api/integration/products',
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => '',
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 0,
  CURLOPT_FOLLOWLOCATION => true,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => 'POST',
  CURLOPT_POSTFIELDS =>'{
    "data": [
        {
            "product_sku_id": "sku_1",
            "amount": "25"
        },
        {
            "product_sku_id": "sku_2",
            "amount": "25"
        },
        {
            "product_sku_id": "sku_3",
            "amount": "25"
        },
        {
            "product_sku_id": "sku_4",
            "amount": "25"
        }
    ]
}',
  CURLOPT_HTTPHEADER => array(
    'token: U78FtRBfsMAnecq_q3i8J',
    'Content-Type: application/json'
  ),
));

$response = curl_exec($curl);

curl_close($curl);
echo $response;

        `,
      },
      "Node-Axios": {
        language: 'javascript',
        code: `
var axios = require('axios');
var data = JSON.stringify({
  "data": [
    {
      "product_sku_id": "sku_1",
      "amount": "25"
    },
    {
      "product_sku_id": "sku_2",
      "amount": "25"
    },
    {
      "product_sku_id": "sku_3",
      "amount": "25"
    },
    {
      "product_sku_id": "sku_4",
      "amount": "25"
    }
  ]
});

var config = {
  method: 'post',
  url: 'localhost:8000/api/integration/products',
  headers: { 
    'token': 'U78FtRBfsMAnecq_q3i8J', 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});

        `,
      },
      "C#-RestSharp": {
        language: 'csharp',
        code: `
var client = new RestClient("localhost:8000/api/integration/products");
client.Timeout = -1;
var request = new RestRequest(Method.POST);
request.AddHeader("token", "U78FtRBfsMAnecq_q3i8J");
request.AddHeader("Content-Type", "application/json");
var body = @"{
" + "\n" +
@"    ""data"": [
" + "\n" +
@"        {
" + "\n" +
@"            ""product_sku_id"": ""sku_1"",
" + "\n" +
@"            ""amount"": ""25""
" + "\n" +
@"        },
" + "\n" +
@"        {
" + "\n" +
@"            ""product_sku_id"": ""sku_2"",
" + "\n" +
@"            ""amount"": ""25""
" + "\n" +
@"        },
" + "\n" +
@"        {
" + "\n" +
@"            ""product_sku_id"": ""sku_3"",
" + "\n" +
@"            ""amount"": ""25""
" + "\n" +
@"        },
" + "\n" +
@"        {
" + "\n" +
@"            ""product_sku_id"": ""sku_4"",
" + "\n" +
@"            ""amount"": ""25""
" + "\n" +
@"        }
" + "\n" +
@"    ]
" + "\n" +
@"}";
request.AddParameter("application/json", body,  ParameterType.RequestBody);
IRestResponse response = client.Execute(request);
Console.WriteLine(response.Content);
        `,
      },
      "Python-http.client": {
        language: 'python',
        code: `
$headers = New-Object "System.Collections.Generic.Dictionary[[String],[String]]"
$headers.Add("token", "U78FtRBfsMAnecq_q3i8J")
$headers.Add("Content-Type", "application/json")

$body = "{
\`n    \`"data\`": [
\`n        {
\`n            \`"product_sku_id\`": \`"sku_1\`",
\`n            \`"amount\`": \`"25\`"
\`n        },
\`n        {
\`n            \`"product_sku_id\`": \`"sku_2\`",
\`n            \`"amount\`": \`"25\`"
\`n        },
\`n        {
\`n            \`"product_sku_id\`": \`"sku_3\`",
\`n            \`"amount\`": \`"25\`"
\`n        },
\`n        {
\`n            \`"product_sku_id\`": \`"sku_4\`",
\`n            \`"amount\`": \`"25\`"
\`n        }
\`n    ]
\`n}"

$response = Invoke-RestMethod 'localhost:8000/api/integration/products' -Method 'POST' -Headers $headers -Body $body
$response | ConvertTo-Json
        `,
      },
      "Java-OkHttp": {
        language: 'java',
        code: `
OkHttpClient client = new OkHttpClient().newBuilder()
  .build();
MediaType mediaType = MediaType.parse("application/json");
RequestBody body = RequestBody.create(mediaType, "{\r\n    \"data\": [\r\n        {\r\n            \"product_sku_id\": \"sku_1\",\r\n            \"amount\": \"25\"\r\n        },\r\n        {\r\n            \"product_sku_id\": \"sku_2\",\r\n            \"amount\": \"25\"\r\n        },\r\n        {\r\n            \"product_sku_id\": \"sku_3\",\r\n            \"amount\": \"25\"\r\n        },\r\n        {\r\n            \"product_sku_id\": \"sku_4\",\r\n            \"amount\": \"25\"\r\n        }\r\n    ]\r\n}");
Request request = new Request.Builder()
  .url("localhost:8000/api/integration/products")
  .method("POST", body)
  .addHeader("token", "U78FtRBfsMAnecq_q3i8J")
  .addHeader("Content-Type", "application/json")
  .build();
Response response = client.newCall(request).execute();
        `,
      },
      Go: {
        language: 'go',
        code: `
package main

import (
  "fmt"
  "strings"
  "net/http"
  "io/ioutil"
)

func main() {

  url := "localhost:8000/api/integration/products"
  method := "POST"

  payload := strings.NewReader(\`{\`+"
"+\`
    "data": [\`+"
"+\`
        {\`+"
"+\`
            "product_sku_id": "sku_1",\`+"
"+\`
            "amount": "25"\`+"
"+\`
        },\`+"
"+\`
        {\`+"
"+\`
            "product_sku_id": "sku_2",\`+"
"+\`
            "amount": "25"\`+"
"+\`
        },\`+"
"+\`
        {\`+"
"+\`
            "product_sku_id": "sku_3",\`+"
"+\`
            "amount": "25"\`+"
"+\`
        },\`+"
"+\`
        {\`+"
"+\`
            "product_sku_id": "sku_4",\`+"
"+\`
            "amount": "25"\`+"
"+\`
        }\`+"
"+\`
    ]\`+"
"+\`
}\`)

  client := &http.Client {
  }
  req, err := http.NewRequest(method, url, payload)

  if err != nil {
    fmt.Println(err)
    return
  }
  req.Header.Add("token", "U78FtRBfsMAnecq_q3i8J")
  req.Header.Add("Content-Type", "application/json")

  res, err := client.Do(req)
  if err != nil {
    fmt.Println(err)
    return
  }
  defer res.Body.Close()

  body, err := ioutil.ReadAll(res.Body)
  if err != nil {
    fmt.Println(err)
    return
  }
  fmt.Println(string(body))
}
        `,
      },
      Ruby: {
        language: 'ruby',
        code: `
require "uri"
require "json"
require "net/http"

url = URI("localhost:8000/api/integration/products")

http = Net::HTTP.new(url.host, url.port);
request = Net::HTTP::Post.new(url)
request["token"] = "U78FtRBfsMAnecq_q3i8J"
request["Content-Type"] = "application/json"
request.body = JSON.dump({
  "data": [
    {
      "product_sku_id": "sku_1",
      "amount": "25"
    },
    {
      "product_sku_id": "sku_2",
      "amount": "25"
    },
    {
      "product_sku_id": "sku_3",
      "amount": "25"
    },
    {
      "product_sku_id": "sku_4",
      "amount": "25"
    }
  ]
})

response = http.request(request)
puts response.read_body

        `,
      },
      C: {
        language: 'c',
        code: `
CURL *curl;
CURLcode res;
curl = curl_easy_init();
if(curl) {
  curl_easy_setopt(curl, CURLOPT_CUSTOMREQUEST, "POST");
  curl_easy_setopt(curl, CURLOPT_URL, "localhost:8000/api/integration/products");
  curl_easy_setopt(curl, CURLOPT_FOLLOWLOCATION, 1L);
  curl_easy_setopt(curl, CURLOPT_DEFAULT_PROTOCOL, "https");
  struct curl_slist *headers = NULL;
  headers = curl_slist_append(headers, "token: U78FtRBfsMAnecq_q3i8J");
  headers = curl_slist_append(headers, "Content-Type: application/json");
  curl_easy_setopt(curl, CURLOPT_HTTPHEADER, headers);
  const char *data = "{\r\n    \"data\": [\r\n        {\r\n            \"product_sku_id\": \"sku_1\",\r\n            \"amount\": \"25\"\r\n        },\r\n        {\r\n            \"product_sku_id\": \"sku_2\",\r\n            \"amount\": \"25\"\r\n        },\r\n        {\r\n            \"product_sku_id\": \"sku_3\",\r\n            \"amount\": \"25\"\r\n        },\r\n        {\r\n            \"product_sku_id\": \"sku_4\",\r\n            \"amount\": \"25\"\r\n        }\r\n    ]\r\n}";
  curl_easy_setopt(curl, CURLOPT_POSTFIELDS, data);
  res = curl_easy_perform(curl);
}
curl_easy_cleanup(curl);

        `,
      },
      PowerShell: {
        language: 'powershell',
        code: `
        
        `,
      },
    },
  },
};
