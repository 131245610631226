import styled from '@emotion/styled';

export const TabsStyle = styled('div')`
  // max-width: 600px;
  // margin: auto;
  .tabs {
    &__list {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      padding-left: 0;
      border-bottom: 1px solid #eee;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__item {
      position: relative;
      display: inline-block;
      text-transform: Capitalize;
      padding: 15px 0;
      margin-right: 15px;
      // width: calc(100% / ${(props) => props.count});
      text-align: center;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        width: 0;
        background: #1ed3c6;
        transition: 0.3s;
      }
      &.active:after {
        width: 100%;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &__content-wrapper {
      position: relative;
    }
    &__content {
      display: none;
      &.active {
        display: block;
      }
    }
  }
`;
